import React from 'react'
import PropTypes from 'prop-types'
import resolveLink from '~utils/resolveLink'
import { css } from '@emotion/react'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import Section from '~components/Section'
import Figure from './Figure'
import {mobile} from '~styles/global'
import BlurIn from '~components/BlurIn'
import VideoModule from '~components/VideoModule'
import Accordion from '~components/Accordion'
import ImageModule from '~components/ImageModule'
import CarouselModule from './CarouselModule'
import useBreakpoint from '~utils/useBreakpoint'
import Button from './Button'
import Num from '~components/Num'

const serializers = {
	marks: {
		internalLink: props => {
			return <Link css={underlineCss} to={resolveLink(props.mark.page)}>{props.children}</Link> 
		},
		link: props => {
			if(props.mark.linkType === 'internal'){
				return <Link css={underlineCss} to={resolveLink(props.mark.document)}>{props.children}</Link> 
			} else {
				return <a css={underlineCss} href={props.mark?.url} target={props.mark.blank ? '_blank' : '_self'} rel='noreferrer'>{props.children}</a>
			}
		},
		number: props => <Num css={css`margin-right: var(--xxs);`}>{props.children}</Num>
	},
	types: {
		collage: props => {
			return (
				<BlurIn>
					<Section css={css`
						margin-top: 159px; 
						margin-bottom: 150px;
						${mobile}{
							margin-top: 58px;
							margin-bottom: 58px;
						}
					`}>
						<Figure 
							content={props.node?.image1} 
							css={css`
								grid-column: 3/5;
								${mobile}{
									grid-column: 1/4;
								}
						`}/>
						<Figure 
							content={props.node?.image2}  
							css={css`
								grid-column: 6/8; 
								margin-top: 142px;
								${mobile}{
									grid-column: 4/7;
									margin-top: 77%;
								}
						`}/>
						<Figure 
							content={props.node?.image3}  
							css={css`
								grid-column: 9/11;
								${mobile}{
									grid-column: 1/4;
									margin-top: -58%;
								}
						`}/>
					</Section>
				</BlurIn>
			)
		},
		quote: props => {
			return (
				<Section css={css`
					margin-top: 150px; 
					margin-bottom: 150px; 
					text-align: center;
					${mobile}{
						margin-top: 90px;
    				margin-bottom: 80px;
					}
				`}>
					<BlurIn 
						css={css`
							grid-column: 3/11;
							${mobile}{
								grid-column: 1/7;
								padding: 0 10%;
							}
					`}>
						<h1 
							className='large' 
							css={css`
								margin-bottom: 25px;
								${mobile}{
									margin-bottom: 28px;
								}
						`}>“{props.node.quote}”</h1>
						<h3>{props.node.attribution}</h3>
					</BlurIn>
				</Section>
			)
		},
		imageModule: props => {
			const { isMobile } = useBreakpoint()
			return (
				<BlurIn>
					<ImageModule image={props.node} isMobile={isMobile}/>
				</BlurIn>
			)
		},
		videoEmbed: props => {
			return (
				<Section css={css`	
					margin-top: 150px; 
					margin-bottom: 150px; 
					text-align: center;
					${mobile}{
						margin-top: 80px;
						margin-bottom: 80px;
				}`}>
					<VideoModule 
						video={props.node.videoUrl}
						placeHolder={props.node.placeholderImage} 
						css={css` 
							grid-column: 3/11;
							${mobile}{
								grid-column: span 6;
							}
						`}
					/>
				</Section>
			)
		},
		accordion: props => {
			return (
				<Section css={css`margin-top: 100px; margin-bottom: 100px;`}>
					<div css={css`
						grid-column: 4/10; 
						border-bottom: 1px solid var(--black);
						${mobile}{
							grid-column: span 6;
						}
					`}>
						{props?.node?.items?.map(item => (
							<Accordion title={item.title} key={item._key} 
								css={css`
									border: 1px solid var(--black);
									border-bottom: none;
								`}
							>
								<RichText content={item.text}/>
							</Accordion>
						))}
					</div>
				</Section>
			)
		},
		callout: props => {
			return (
				<Section css={css`
					margin-top: 100px; 
					margin-bottom: 100px;
					${mobile}{
						margin-top: 80px;
						margin-bottom: 80px;
					}
				`}>
					<BlurIn css={css`
						grid-column: 4/10; 
						border: 1px solid var(--black);
						padding: 50px 40px 44px;
						text-align: center;
						${mobile}{
							grid-column: span 6;
							padding: 38px 30px 38px;
						}
					`}>
						<h2>
							<RichText content={props.node.text}/>
						</h2>
					</BlurIn>
				</Section>
			)
		},
		carousel: props => {
			return (
				<BlurIn>
					<CarouselModule 
						slides={props?.node?.slides} 
						css={css`
							margin-top: 100px;
							margin-bottom: 100px;
							${mobile}{
								margin-bottom: 86px;
							}
						`}
					/>
				</BlurIn>
			)
		},
		buttonRow: props => {
			return (
				<div 
					css={css`
						margin-top: 88px;
						margin-bottom: 88px;
						width: calc(52% - 10px);
						margin-left: auto;
						margin-right: auto;
						padding: 0 40px;
						box-sizing: border-box;
						max-width: 736px;
						${mobile}{
							margin-top: 58px;
							margin-bottom: 40px;
						}
					`}
				>
					<BlurIn 
						css={css`
							display: flex;
							flex-wrap: wrap;
					`}>
						{props?.node?.buttons.map(button => (
							<Button 
								link={button?.link} 
								className='h3' 
								key={button?._key}
								css={css`
								display: block;
								margin-right: var(--l);
								&:last-of-type{
									margin-right: 0px;
								}
								${mobile}{
									width: 100%;
									text-align: center;
									margin-right: 0px;
									margin-bottom: var(--l);
								}
							`}>
								{button?.text}
							</Button>
						))}
					</BlurIn>
				</div>
			)
		},
	}
}

const RichText = ({ content }) =>
	<BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>


const underlineCss = css`
	position: relative;
	display: inline-block;
	&:after{
		content: '';
		width: 100%;
		height: 1px;
		background-color: var(--black);
		transition: transform 0.2s;
		position: absolute;
		bottom: 0px;
		left: 0;
		display: block;
		transform: translateY(-2px);
	}
`

RichText.propTypes = {
	content: PropTypes.array,
}

export default RichText
